import React from 'react';

const LangtangTrek = () => {
  return (
    <div>
      <h1>Langtang Trek</h1>
      {/* Add content specific to Langtang trek */}
    </div>
  );
};

export default LangtangTrek;