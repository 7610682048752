import React from 'react';

const ThreePasses = () => {
  return (
    <div>
      <h1>Three Passes Trek</h1>
      {/* Add content specific to Three Passes trek */}
    </div>
  );
};

export default ThreePasses;
