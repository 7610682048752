import React from 'react';

const DhaulagiriBaseCamp = () => {
  return (
    <div>
      <h1>Dhaulagiri Base Camp Trek</h1>
      {/* Add content specific to Dhaulagiri Base Camp trek */}
    </div>
  );
};

export default DhaulagiriBaseCamp;