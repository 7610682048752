import React from 'react';

const UpperMustang = () => {
  return (
    <div>
      <h1>Upper Mustang Trek</h1>
      {/* Add content specific to Upper Mustang trek */}
    </div>
  );
};

export default UpperMustang;