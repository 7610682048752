import React from 'react';

const KathmanduPokhara = () => {
  return (
    <div>
      <h1>Kathmandu & Pokhara Tour</h1>
      {/* Add content specific to Kathmandu & Pokhara tour */}
    </div>
  );
};

export default KathmanduPokhara;