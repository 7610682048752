import React from 'react';

const EverestBaseCamp = () => {
  return (
    <div>
      <h1>Everest Base Camp Trek</h1>
      {/* Add content specific to Everest Base Camp trek */}
    </div>
  );
};

export default EverestBaseCamp;