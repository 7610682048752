import React from 'react';

const KathmanduChitwan = () => {
  return (
    <div>
      <h1>Kathmandu & Chitwan Tour</h1>
      {/* Add content specific to Kathmandu & Chitwan tour */}
    </div>
  );
};

export default KathmanduChitwan;