import React from 'react';

const KathmanduLumbini = () => {
  return (
    <div>
      <h1>Kathmandu & Lumbini Tour</h1>
      {/* Add content specific to Kathmandu & Lumbini tour */}
    </div>
  );
};

export default KathmanduLumbini;