import React from 'react';

const KathmanduNagarkot = () => {
  return (
    <div>
      <h1>Kathmandu & Nagarkot Tour</h1>
      {/* Add content specific to Kathmandu & Nagarkot tour */}
    </div>
  );
};

export default KathmanduNagarkot;